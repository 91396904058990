import React, { Component } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import * as PropTypes from 'prop-types'
import { TranslatedLink } from '../components/TranslatedLink'
import { getTranslateFunction, shiftHTags, slugFormat, toLineBreak } from '../utilities'

import Img from 'gatsby-image'
import LinkBtn from '../components/linkBtn/LinkBtn'

import PageTitle from '../components/pageTitle'
import CancelDragClick from '../components/cancelDragClick'
import AliceCarousel from '../lib/react-alice-carousel/react-alice-carousel'
import Reveal from '../components/reveal'
import '../components/blog/Blog.scss'
import ContaktUs from '../components/contactUs/ContactUs'
import Footer from '../layouts/footer'

class Blog extends Component {

  constructor(props) {
    super(props)
    this.twitterPopUp = this.twitterPopUp.bind(this)
    this.facebookPopUp = this.facebookPopUp.bind(this)
  }

  twitterPopUp() {
    const winWidth = 600
    const winCenter = (window.screen.width - winWidth) / 2
    window.open(`https://twitter.com/intent/tweet?url=https://narative.sk${this.props.location.pathname}`, '', 'width=' + winWidth + ',height=' + window.screen.height + ',left=' + winCenter)
  }

  facebookPopUp() {
    const winWidth = 600
    const winCenter = (window.screen.width - winWidth) / 2
    const url = `https://www.facebook.com/v3.1/dialog/share?app_id=452454992040565&display=popup&href=https://narative.sk${encodeURIComponent(this.props.location.pathname)}`;
    window.open(url, '', 'width=' + winWidth + ',height=' + window.screen.height + ',left=' + winCenter)
  }

  render() {
    let { data } = this.props

    const blogPost = data.blog.frontmatter
    let featuredBlogPost = []

    if (data.featured) {
      featuredBlogPost = data.featured.edges.map((edge) => {
        return { slug: edge.node.fields.slug, ...edge.node.frontmatter }
      })
    }

    let latestBlogPost = []
    if (data.latest) {
      latestBlogPost = data.latest.edges.map((edge) => {
        return { slug: edge.node.fields.slug, ...edge.node.frontmatter }
      })
    }

    if (featuredBlogPost.length < 3) {
      featuredBlogPost = featuredBlogPost.concat(latestBlogPost).slice(0, 3)
    }

    const date = new Date(blogPost.date).toLocaleDateString()

    const t = getTranslateFunction(blogPost.language)
    
    return (
      <Layout showBackButton={true} lang={blogPost.language} >
        <div className="blog-detail">
          <section className="subpage-title mb-4 margin-top blog">
            <div className="container">
              <div className="row">
                <div className="col-md-9 m-auto">
                  <PageTitle seoTitle={blogPost.seo_title} title={blogPost.title} url={this.props.location.href} image={blogPost.thumbnail.childImageSharp.fixed.src} description={blogPost.seo_description} />
                  <span className="tag">
                                  <img className="image" src="/images/svg/icon_tag.svg" alt={blogPost.category}/>
                    {blogPost.category}
                    <span className="article-time">
                                      <img className="image" src="/images/icon_point.png" alt={date}/>
                      {date}</span>
                              </span>
                </div>
              </div>
            </div>
          </section>

          <div className="blog-detail-img">
            <Img className="image" fluid={blogPost.coveImage.childImageSharp.fluid} alt={blogPost.title}/>
          </div>

          <section className="blog-detail-content mb-2 mb-md-4">
            <div className="container">
              <div className="row">
                <div className="col-md-9 m-auto pt-5">
                  <article dangerouslySetInnerHTML={{ __html: toLineBreak(shiftHTags(data.blog.html)) }}/>
                  <div className="blog-detail-social">
                    <div className="row d-flex align-items-center justify-content-end">
                      <div className="col-12 col-md-auto">
                        <span className="blog-social">
                          <button onClick={this.facebookPopUp}>
                                <img className="image facebook-icon" src="/images/svg/icon_fb.svg" alt="facebook"/>
                                <span>{t('BlogDetail.FacebookShare')}</span>
                              </button>
                        </span>
                      </div>
                      <div className="col-12 col-md-auto">
                        <span className="blog-social">
                          <button onClick={this.twitterPopUp}>
                            <img className="image twitter-icon" src="/images/svg/icon_twitter.svg" alt="twitter"/>
                            <span>{t('BlogDetail.TwitterShare')}</span>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <h2 className='text-center mb-5'>Ďalšie články</h2>
                  <div className="blog-detail-featured">
                    <div className={'d-md-none'}>
                      <AliceCarousel
                        duration={500}
                        mouseTracking
                        infinite
                        responsive={{
                          0: { items: 1 },
                          500: { items: 2 },
                          920: { items: 3 },
                        }}
                        autoPlay={false}
                        autoPlayInterval={15 * 1000}
                        disableButtonsControls={true}
                        items={featuredBlogPost.map((post, i) => {
                          const slug = `${blogPost.language === 'en' ? '/en' : ''}/blog/${slugFormat(post.title)}/`

                          return (
                            <CancelDragClick
                              key={i}
                              threshold={1}>
                              <TranslatedLink
                                to={slug}
                                title-bak={post.title}>
                                <div className="row item">
                                  <div className="col-md-3 left">

                                    <Img className="image"
                                        style={{ height: 150, width: '100%' }}
                                        objectFit={'cover'}
                                        fixed={post.thumbnail.childImageSharp.fixed}
                                        alt={post.title}
                                        imgStyle={{ transition: 'opacity 500ms ease 0s, all 0.2s ease-in-out' }}/>
                                  </div>
                                  <div className="col-md-9 right">
                                          <span className="tag">
                                              <img className="image" src="/images/svg/icon_tag.svg" alt={post.category}/>
                                            {post.category}
                                          </span>
                                    <h3>{post.title}</h3>
                                    <p>{post.perex}</p>
                                  </div>
                                </div>
                              </TranslatedLink>
                            </CancelDragClick>
                          )
                        })}/>
                    </div>
                    <div className={'d-none d-md-block'}>
                      {featuredBlogPost.map((post, i) => {
                        const slug = `${blogPost.language === 'en' ? '/en' : ''}/blog/${slugFormat(post.title)}/`

                        return (
                          <TranslatedLink
                            key={i}
                            to={slug}
                            title-bak={post.title}>
                            <div className="row item">
                              <div className="col-md-3 left">

                                <Img className="image"
                                    style={{ height: 150, width: '100%' }}
                                    objectFit={'cover'}
                                    fixed={post.thumbnail.childImageSharp.fixed}
                                    alt={post.title}
                                    imgStyle={{ transition: 'opacity 500ms ease 0s, all 0.2s ease-in-out' }}/>
                              </div>
                              <div className="col-md-9 right">
                                          <span className="tag">
                                              <img className="image" src="/images/svg/icon_tag.svg" alt={post.category}/>
                                            {post.category}
                                          </span>
                                <h3>{post.title}</h3>
                                <p>{post.perex}</p>
                              </div>
                            </div>
                          </TranslatedLink>
                        )
                      })}
                    </div>
                  </div>
                  <div className="row mb-0 mb-md-5 mt-md-0 mt-md-2 mt-5">
                    <div className="col">
                      <LinkBtn>
                        <TranslatedLink to="/en/blog">{t('BlogDetail.MoreBtn')}</TranslatedLink>
                      </LinkBtn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Reveal>
            <section className="casestudy-block cat-block about-us-projects">
              <div className="container">
                <h3>Všetky naše referencie</h3>
                <LinkBtn>
                  <TranslatedLink to="/en/our-works/featured">Pozrite si práce</TranslatedLink>
                </LinkBtn>
              </div>
            </section>
          </Reveal>

           <section className='d-block d-md-none text-center'>
              <ContaktUs
                LeftSideTitle='category'
                LeftSideSubTitle={'Design'}
                LeftSideLink={'/en/services/design'}
                leftClassName={'d-none'}
                linkRight="/en/contact"
                rightSecondLink="/en/brief"
              />
            </section>

            <footer>
                <Footer lang={blogPost.language} />
            </footer>    
        </div>
      </Layout>
    )
  }
}

Blog.propTypes = { data: PropTypes.any }

export const query = graphql`query ($category: String!, $slug: String!, $language: String!) {
    blog: markdownRemark(fields: {slug: {eq: $slug}}) {
        html
        frontmatter {
            language
            seo_title
            seo_description
            title
            perex
            category
            thumbnail {
                childImageSharp {
                    fixed( width: 1200, height: 628) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
            coveImage {
                childImageSharp {
                    fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            date
        }
    }
    featured: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(/blog/)/"}, frontmatter: {category: {eq: $category}, language: {eq: $language}}, fields: {slug: {ne: $slug}}}, limit: 3, sort: {fields: [frontmatter___date], order: DESC}) {
        edges {
            node {
                id
                fields {
                    slug
                }
                frontmatter {
                    title
                    perex
                    category
                    thumbnail {
                        childImageSharp {
                            fixed( width: 500) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    coveImage {
                        childImageSharp {
                            fixed( width: 500) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    perex
                    date
                }
            }
        }
    }
    latest: allMarkdownRemark(filter: {fields: {slug: {regex: "/(/blog/)/"}}, frontmatter: {category: {ne: $category}, language: {eq: $language}}}, limit: 3, sort: {fields: [frontmatter___date], order: DESC}) {
        edges {
            node {
                id
                fields {
                    slug
                }
                frontmatter {
                    title
                    perex
                    category
                    thumbnail {
                        childImageSharp {
                            fixed( width: 500) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    coveImage {
                        childImageSharp {
                            fixed( width: 500) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    perex
                    date
                }
            }
        }
    }
}
`

export default Blog
